"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getModal = void 0;
var reselect_1 = require("reselect");
var getRoot = function (_a) {
    var modals = _a.modals;
    return modals;
};
var getModal = function (modal) {
    return reselect_1.createSelector(getRoot, function (state) { return state[modal]; });
};
exports.getModal = getModal;
