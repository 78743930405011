"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatAvailabilityForMxt = void 0;
var marxent_1 = require("../marxent");
var interfaces_1 = require("../interfaces");
var formatAvailabilityForMxt = function (availability) {
    return Object.entries(availability).map(function (_a) {
        var key = _a[0], value = _a[1];
        return ({
            ean: key,
            availability: value === interfaces_1.FulfilmentAvailability.Available
                ? marxent_1.MxtAvailability.Available
                : marxent_1.MxtAvailability.Unavailable,
        });
    });
};
exports.formatAvailabilityForMxt = formatAvailabilityForMxt;
