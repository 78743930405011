"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.processProducts = void 0;
var processProducts = function (mxtProducts) {
    var assemblies = mxtProducts.map(function (assembly) {
        var subProducts = assembly.subProducts, productProps = __rest(assembly, ["subProducts"]);
        var nestedProducts = __spreadArrays([productProps], iterateBomSubProducts(subProducts));
        var products = nestedProducts.map(function (product) { return ({
            ean: product.sku,
            quantity: product.qty,
            name: product.name,
        }); });
        return {
            ean: assembly.sku,
            name: assembly.name,
            quantity: assembly.qty,
            products: products,
        };
    });
    return {
        groups: [
            {
                name: null,
                assemblies: assemblies,
            },
        ],
    };
};
exports.processProducts = processProducts;
var iterateBomSubProducts = function (subProducts, accProducts) {
    if (subProducts === void 0) { subProducts = []; }
    if (accProducts === void 0) { accProducts = []; }
    for (var _i = 0, subProducts_1 = subProducts; _i < subProducts_1.length; _i++) {
        var product = subProducts_1[_i];
        accProducts.push(product);
        if (product.subProducts) {
            iterateBomSubProducts(product.subProducts, accProducts);
        }
    }
    return accProducts;
};
