"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.post = void 0;
const constants_1 = require("../constants");
const post = (targetOrigin, target = window.parent) => (kind, payload) => {
    if (!(target instanceof MessagePort) && !(target instanceof ServiceWorker)) {
        const message = {
            namespace: constants_1.MSG_NAMESPACE,
            kind,
            data: payload,
        };
        target && target.postMessage(message, targetOrigin);
    }
};
exports.post = post;
