"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getIsDirty = void 0;
var reselect_1 = require("reselect");
var getRoot = function (_a) {
    var communicator = _a.communicator;
    return communicator;
};
exports.getIsDirty = reselect_1.createSelector(getRoot, function (_a) {
    var isDirty = _a.isDirty;
    return isDirty;
});
