"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.actions = exports.ActionTypes = void 0;
var helpers_1 = require("@hip/helpers");
var ActionTypes;
(function (ActionTypes) {
    ActionTypes["INIT"] = "@configurator/utils/INIT";
    ActionTypes["SET_CONFIG"] = "@configurator/utils/SET_CONFIG";
    ActionTypes["SET_OPCO"] = "@configurator/utils/SET_OPCO";
})(ActionTypes = exports.ActionTypes || (exports.ActionTypes = {}));
exports.actions = {
    init: function () { return helpers_1.createAction(ActionTypes.INIT); },
    setConfig: function (payload) {
        return helpers_1.createAction(ActionTypes.SET_CONFIG, payload);
    },
    setOPCO: function (payload) { return helpers_1.createAction(ActionTypes.SET_OPCO, payload); },
};
