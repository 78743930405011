"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.actions = exports.ActionTypes = void 0;
var helpers_1 = require("@hip/helpers");
var ActionTypes;
(function (ActionTypes) {
    ActionTypes["OPEN"] = "@configurator/modals/OPEN";
    ActionTypes["CLOSE"] = "@configurator/modals/CLOSE";
})(ActionTypes = exports.ActionTypes || (exports.ActionTypes = {}));
exports.actions = {
    open: function (payload) { return helpers_1.createAction(ActionTypes.OPEN, payload); },
    close: function (payload) { return helpers_1.createAction(ActionTypes.CLOSE, payload); },
};
