"use strict";
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.sagas = exports.mxtSetProductsData = exports.mxtEvent = exports.mxtInit = void 0;
var effects_1 = require("redux-saga/effects");
var communicator_1 = require("../communicator");
var actions_1 = require("./actions");
var interfaces_1 = require("./interfaces");
var utils_1 = require("./utils");
var jsCommunicator;
function mxtInit(_a) {
    var payload = _a.payload;
    jsCommunicator = payload;
    jsCommunicator.setProductsData = jsCommunicator.setProductsData.bind(jsCommunicator);
}
exports.mxtInit = mxtInit;
function mxtEvent(_a) {
    var _b, e_1;
    var mxtEvent = _a.payload.mxtEvent;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _c.trys.push([0, 10, , 11]);
                _b = mxtEvent.kind;
                switch (_b) {
                    case interfaces_1.MxtConfiguratorEventKind.ATOMIA_BOM_LOADED: return [3 /*break*/, 1];
                    case interfaces_1.MxtConfiguratorEventKind.FIREPLACE_BOM_LOADED: return [3 /*break*/, 1];
                    case interfaces_1.MxtConfiguratorEventKind.ALARA_BOM_LOADED: return [3 /*break*/, 1];
                    case interfaces_1.MxtConfiguratorEventKind.DIRTY_STATE_CHANGED: return [3 /*break*/, 3];
                    case interfaces_1.MxtConfiguratorEventKind.ATOMIA_ADD_TO_CART_LOADED: return [3 /*break*/, 5];
                    case interfaces_1.MxtConfiguratorEventKind.FIREPLACE_ADD_TO_CART_LOADED: return [3 /*break*/, 5];
                    case interfaces_1.MxtConfiguratorEventKind.ALARA_ADD_TO_CART_LOADED: return [3 /*break*/, 5];
                    case interfaces_1.MxtConfiguratorEventKind.GET_PRODUCTS_DATA: return [3 /*break*/, 7];
                }
                return [3 /*break*/, 9];
            case 1: return [4 /*yield*/, effects_1.put(communicator_1.actions.productList({
                    bom: utils_1.processProducts(mxtEvent.json),
                }))];
            case 2:
                _c.sent();
                return [3 /*break*/, 9];
            case 3: return [4 /*yield*/, effects_1.put(communicator_1.actions.dirtyStateChanged({
                    isDirty: mxtEvent.isDirty,
                }))];
            case 4:
                _c.sent();
                return [3 /*break*/, 9];
            case 5: return [4 /*yield*/, effects_1.put(communicator_1.actions.addToBasket({
                    bom: utils_1.processProducts(mxtEvent.json),
                }))];
            case 6:
                _c.sent();
                return [3 /*break*/, 9];
            case 7: return [4 /*yield*/, effects_1.put(communicator_1.actions.getProductsData({
                    eans: mxtEvent.eans,
                }))];
            case 8:
                _c.sent();
                return [3 /*break*/, 9];
            case 9: return [3 /*break*/, 11];
            case 10:
                e_1 = _c.sent();
                console.error(e_1);
                return [3 /*break*/, 11];
            case 11: return [2 /*return*/];
        }
    });
}
exports.mxtEvent = mxtEvent;
function mxtSetProductsData(_a) {
    var products = _a.payload.products;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, effects_1.call([jsCommunicator, jsCommunicator.setProductsData], products)];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
exports.mxtSetProductsData = mxtSetProductsData;
function sagas() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, effects_1.takeLatest(actions_1.ActionTypes.MXT_INIT, mxtInit)];
            case 1:
                _a.sent();
                return [4 /*yield*/, effects_1.takeLatest(actions_1.ActionTypes.MXT_EVENT, mxtEvent)];
            case 2:
                _a.sent();
                return [4 /*yield*/, effects_1.takeLatest(actions_1.ActionTypes.MXT_SET_PRODUCTS_DATA, mxtSetProductsData)];
            case 3:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
exports.sagas = sagas;
