"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createRootReducers = void 0;
var redux_1 = require("redux");
var utils_1 = require("./utils");
var modals_1 = require("./modals");
var communicator_1 = require("./communicator");
var createRootReducers = function () {
    return redux_1.combineReducers({
        utils: utils_1.utils,
        modals: modals_1.modals,
        communicator: communicator_1.communicator,
    });
};
exports.createRootReducers = createRootReducers;
