"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.actions = exports.ActionTypes = void 0;
var helpers_1 = require("@hip/helpers");
var ActionTypes;
(function (ActionTypes) {
    ActionTypes["ADD_TO_BASKET"] = "@configurator/communicator/ADD_TO_BASKET";
    ActionTypes["BOOTSTRAP"] = "@configurator/communicator/BOOTSTRAP";
    ActionTypes["EXIT"] = "@configurator/communicator/EXIT";
    ActionTypes["HOME"] = "@configurator/communicator/HOME";
    ActionTypes["GET_PRODUCT_PRICE"] = "@configurator/communicator/GET_PRODUCT_PRICE";
    ActionTypes["PRODUCT_LIST"] = "@configurator/communicator/PRODUCT_LIST";
    ActionTypes["GET_PRODUCTS_DATA"] = "@configurator/communicator/GET_PRODUCTS_DATA";
    ActionTypes["DIRTY_STATE_CHANGED"] = "@configurator/communicator/DIRTY_STATE_CHANGED";
})(ActionTypes = exports.ActionTypes || (exports.ActionTypes = {}));
exports.actions = {
    addToBasket: function (payload) {
        return helpers_1.createAction(ActionTypes.ADD_TO_BASKET, payload);
    },
    bootstrap: function () { return helpers_1.createAction(ActionTypes.BOOTSTRAP); },
    exit: function () { return helpers_1.createAction(ActionTypes.EXIT); },
    home: function () { return helpers_1.createAction(ActionTypes.HOME); },
    getProductPrice: function () { return helpers_1.createAction(ActionTypes.GET_PRODUCT_PRICE); },
    productList: function (payload) {
        return helpers_1.createAction(ActionTypes.PRODUCT_LIST, payload);
    },
    getProductsData: function (payload) {
        return helpers_1.createAction(ActionTypes.GET_PRODUCTS_DATA, payload);
    },
    dirtyStateChanged: function (payload) {
        return helpers_1.createAction(ActionTypes.DIRTY_STATE_CHANGED, payload);
    },
};
