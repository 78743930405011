"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.init = void 0;
const constants_1 = require("../constants");
const interfaces_1 = require("../interfaces");
const init = (url, target, actions) => {
    if (!url || !target) {
        throw Error('Must have a target and url');
    }
    target && target.setAttribute('src', url);
    target.setAttribute('scrolling', 'no');
    target.setAttribute('width', '100%');
    target.setAttribute('height', '100%');
    const executeAction = (msg) => {
        switch (msg.kind) {
            case interfaces_1.CONFIGURATOR_COMMUNICATOR_EVENTS.ADD_TO_BASKET:
                actions.addToBasket(msg.data);
                break;
            case interfaces_1.CONFIGURATOR_COMMUNICATOR_EVENTS.EXIT:
                actions.exit();
                break;
            case interfaces_1.CONFIGURATOR_COMMUNICATOR_EVENTS.HOME:
                actions.home();
                break;
            case interfaces_1.CONFIGURATOR_COMMUNICATOR_EVENTS.PRODUCT_LIST:
                actions.productList(msg.data);
                break;
            case interfaces_1.CONFIGURATOR_COMMUNICATOR_EVENTS.GET_PRODUCTS_DATA:
                actions.getProductsData(msg.data);
                break;
        }
    };
    const eventHandler = (event) => {
        const msg = event.data;
        if (!(event.source instanceof MessagePort) &&
            msg.namespace === constants_1.MSG_NAMESPACE &&
            url.includes(event.origin)) {
            executeAction(msg);
        }
    };
    window.addEventListener('message', eventHandler);
    return {
        unsubscribe: () => window.removeEventListener('message', eventHandler),
    };
};
exports.init = init;
