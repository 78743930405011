"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MxtAvailability = exports.MxtConfiguratorEventKind = void 0;
var MxtConfiguratorEventKind;
(function (MxtConfiguratorEventKind) {
    MxtConfiguratorEventKind["ATOMIA_BOM_LOADED"] = "AtomiaBomLoaded";
    MxtConfiguratorEventKind["ATOMIA_ADD_TO_CART_LOADED"] = "AtomiaAtcLoaded";
    MxtConfiguratorEventKind["FIREPLACE_BOM_LOADED"] = "FireplaceBomLoaded";
    MxtConfiguratorEventKind["FIREPLACE_ADD_TO_CART_LOADED"] = "FireplaceAtcLoaded";
    MxtConfiguratorEventKind["ALARA_BOM_LOADED"] = "AlaraBomLoaded";
    MxtConfiguratorEventKind["ALARA_ADD_TO_CART_LOADED"] = "AlaraAtcLoaded";
    MxtConfiguratorEventKind["GET_PRODUCTS_DATA"] = "GetProductsData";
    MxtConfiguratorEventKind["DIRTY_STATE_CHANGED"] = "DirtyStateChanged";
})(MxtConfiguratorEventKind = exports.MxtConfiguratorEventKind || (exports.MxtConfiguratorEventKind = {}));
var MxtAvailability;
(function (MxtAvailability) {
    MxtAvailability["Available"] = "Available";
    MxtAvailability["Unavailable"] = "Unavailable";
})(MxtAvailability = exports.MxtAvailability || (exports.MxtAvailability = {}));
