"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.sagas = void 0;
var redux_saga_1 = require("redux-saga");
var effects_1 = require("redux-saga/effects");
var configurator_communicator_1 = __importStar(require("@hip/configurator-communicator"));
var selectors_1 = require("../utils/selectors");
var actions_1 = require("../marxent/actions");
var actions_2 = require("./actions");
var utils_1 = require("./utils");
var postMessage;
function bootstrap() {
    var origin_1, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 5, , 6]);
                return [4 /*yield*/, effects_1.select(selectors_1.getOrigin)];
            case 1:
                origin_1 = _a.sent();
                if (!origin_1) return [3 /*break*/, 3];
                postMessage = configurator_communicator_1.default.post(origin_1, window.parent);
                return [4 /*yield*/, effects_1.fork(watchEvents)];
            case 2:
                _a.sent();
                return [3 /*break*/, 4];
            case 3: throw Error('Missing window.postMessage origin');
            case 4: return [3 /*break*/, 6];
            case 5:
                e_1 = _a.sent();
                throw Error(e_1);
            case 6: return [2 /*return*/];
        }
    });
}
var createChannel = function (channel, origin) {
    return redux_saga_1.eventChannel(function (emit) {
        var listener = function (event) {
            var message = event.data;
            if (!(event.source instanceof MessagePort) &&
                message.namespace === configurator_communicator_1.MSG_NAMESPACE &&
                (origin === '*' || event.origin.includes(origin))) {
                switch (message.kind) {
                    case configurator_communicator_1.CONFIGURATOR_COMMUNICATOR_PARENT_EVENTS.SET_PRODUCTS_DATA:
                        emit({
                            type: configurator_communicator_1.CONFIGURATOR_COMMUNICATOR_PARENT_EVENTS.SET_PRODUCTS_DATA,
                            event: message,
                        });
                        break;
                }
            }
        };
        channel.addEventListener('message', listener);
        return function () {
            channel.removeEventListener('message', listener);
        };
    });
};
var watchEvents = function watchEvents() {
    var origin, channel, payload, _a, e_2;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, effects_1.select(selectors_1.getOrigin)];
            case 1:
                origin = _b.sent();
                return [4 /*yield*/, effects_1.call(createChannel, window, origin)];
            case 2:
                channel = _b.sent();
                _b.label = 3;
            case 3:
                if (!true) return [3 /*break*/, 12];
                _b.label = 4;
            case 4:
                _b.trys.push([4, 9, , 11]);
                return [4 /*yield*/, effects_1.take(channel)];
            case 5:
                payload = _b.sent();
                if (!payload) return [3 /*break*/, 8];
                _a = payload.type;
                switch (_a) {
                    case configurator_communicator_1.CONFIGURATOR_COMMUNICATOR_PARENT_EVENTS.SET_PRODUCTS_DATA: return [3 /*break*/, 6];
                }
                return [3 /*break*/, 8];
            case 6: return [4 /*yield*/, effects_1.put(actions_1.actions.mxtSetProductsData({
                    products: utils_1.formatAvailabilityForMxt(payload.event.data),
                }))];
            case 7:
                _b.sent();
                _b.label = 8;
            case 8: return [3 /*break*/, 11];
            case 9:
                e_2 = _b.sent();
                return [4 /*yield*/, effects_1.call(channel.close)];
            case 10:
                _b.sent();
                return [3 /*break*/, 11];
            case 11: return [3 /*break*/, 3];
            case 12: return [2 /*return*/];
        }
    });
};
function addToBasket(_a) {
    var e_3;
    var bom = _a.payload.bom;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 2, , 3]);
                return [4 /*yield*/, effects_1.call(postMessage, configurator_communicator_1.CONFIGURATOR_COMMUNICATOR_EVENTS.ADD_TO_BASKET, bom)];
            case 1:
                _b.sent();
                return [3 /*break*/, 3];
            case 2:
                e_3 = _b.sent();
                throw Error(e_3);
            case 3: return [2 /*return*/];
        }
    });
}
function exit() {
    var e_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, effects_1.call(postMessage, configurator_communicator_1.CONFIGURATOR_COMMUNICATOR_EVENTS.EXIT)];
            case 1:
                _a.sent();
                return [3 /*break*/, 3];
            case 2:
                e_4 = _a.sent();
                throw Error(e_4);
            case 3: return [2 /*return*/];
        }
    });
}
function home() {
    var e_5;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, effects_1.call(postMessage, configurator_communicator_1.CONFIGURATOR_COMMUNICATOR_EVENTS.HOME)];
            case 1:
                _a.sent();
                return [3 /*break*/, 3];
            case 2:
                e_5 = _a.sent();
                throw Error(e_5);
            case 3: return [2 /*return*/];
        }
    });
}
function productList(_a) {
    var e_6;
    var bom = _a.payload.bom;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 2, , 3]);
                return [4 /*yield*/, effects_1.call(postMessage, configurator_communicator_1.CONFIGURATOR_COMMUNICATOR_EVENTS.PRODUCT_LIST, bom)];
            case 1:
                _b.sent();
                return [3 /*break*/, 3];
            case 2:
                e_6 = _b.sent();
                throw Error(e_6);
            case 3: return [2 /*return*/];
        }
    });
}
var getProductsData = function (_a) {
    var eans = _a.payload.eans;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, effects_1.call(postMessage, configurator_communicator_1.CONFIGURATOR_COMMUNICATOR_EVENTS.GET_PRODUCTS_DATA, eans)];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
};
function sagas() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, effects_1.takeLatest(actions_2.ActionTypes.ADD_TO_BASKET, addToBasket)];
            case 1:
                _a.sent();
                return [4 /*yield*/, effects_1.takeLatest(actions_2.ActionTypes.BOOTSTRAP, bootstrap)];
            case 2:
                _a.sent();
                return [4 /*yield*/, effects_1.takeLatest(actions_2.ActionTypes.EXIT, exit)];
            case 3:
                _a.sent();
                return [4 /*yield*/, effects_1.takeLatest(actions_2.ActionTypes.HOME, home)];
            case 4:
                _a.sent();
                return [4 /*yield*/, effects_1.takeLatest(actions_2.ActionTypes.PRODUCT_LIST, productList)];
            case 5:
                _a.sent();
                return [4 /*yield*/, effects_1.takeLatest(actions_2.ActionTypes.GET_PRODUCTS_DATA, getProductsData)];
            case 6:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
exports.sagas = sagas;
