"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.actions = exports.ActionTypes = void 0;
var helpers_1 = require("@hip/helpers");
var ActionTypes;
(function (ActionTypes) {
    ActionTypes["MXT_INIT"] = "@configurator/marxent/MXT_INIT";
    ActionTypes["MXT_EVENT"] = "@configurator/marxent/MXT_EVENT";
    ActionTypes["MXT_SET_PRODUCTS_DATA"] = "@configurator/marxent/MXT_SET_PRODUCTS_DATA";
})(ActionTypes = exports.ActionTypes || (exports.ActionTypes = {}));
exports.actions = {
    mxtInit: function (payload) {
        return helpers_1.createAction(ActionTypes.MXT_INIT, payload);
    },
    mxtEvent: function (payload) {
        return helpers_1.createAction(ActionTypes.MXT_EVENT, payload);
    },
    mxtSetProductsData: function (payload) {
        return helpers_1.createAction(ActionTypes.MXT_SET_PRODUCTS_DATA, payload);
    },
};
