"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.sagas = exports.init = void 0;
var effects_1 = require("redux-saga/effects");
var translations_1 = __importStar(require("@hip/translations"));
var util_sagas_1 = require("@hip/util-sagas");
var communicator_1 = require("../communicator");
var actions_1 = require("./actions");
var requests_1 = require("./requests");
function init() {
    var _a, opco, config, e_1;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 8, , 9]);
                return [4 /*yield*/, effects_1.call(requests_1.fetchConfig)];
            case 1:
                _a = _b.sent(), opco = _a.opco, config = _a.config;
                if (!(config && opco)) return [3 /*break*/, 6];
                return [4 /*yield*/, effects_1.put(actions_1.actions.setConfig(config))];
            case 2:
                _b.sent();
                return [4 /*yield*/, effects_1.put(actions_1.actions.setOPCO(opco))];
            case 3:
                _b.sent();
                return [4 /*yield*/, effects_1.call(translations_1.default, opco)];
            case 4:
                _b.sent();
                return [4 /*yield*/, effects_1.put(communicator_1.actions.bootstrap())];
            case 5:
                _b.sent();
                return [3 /*break*/, 7];
            case 6: throw Error(translations_1._t('messages.error'));
            case 7: return [3 /*break*/, 9];
            case 8:
                e_1 = _b.sent();
                throw Error(translations_1._t('messages.error'));
            case 9: return [2 /*return*/];
        }
    });
}
exports.init = init;
function sagas() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, effects_1.takeLatest(actions_1.ActionTypes.INIT, init)];
            case 1:
                _a.sent();
                return [4 /*yield*/, effects_1.takeLatest(util_sagas_1.RequireConfirmationActionTypes.INIT, util_sagas_1.requireConfirmation)];
            case 2:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
exports.sagas = sagas;
