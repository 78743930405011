"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getOrigin = exports.getMxtAppUrls = exports.getOPCO = exports.getConfig = void 0;
var reselect_1 = require("reselect");
var getRoot = function (_a) {
    var utils = _a.utils;
    return utils;
};
exports.getConfig = reselect_1.createSelector(getRoot, function (_a) {
    var config = _a.config;
    return config;
});
exports.getOPCO = reselect_1.createSelector(getRoot, function (_a) {
    var opco = _a.opco;
    return opco;
});
exports.getMxtAppUrls = reselect_1.createSelector(exports.getConfig, function (config) { var _a; return (_a = config === null || config === void 0 ? void 0 : config.marxent) === null || _a === void 0 ? void 0 : _a.urls; });
exports.getOrigin = reselect_1.createSelector(exports.getConfig, function (config) { return config && config.communicator.origin; });
