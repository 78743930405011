"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CONFIGURATOR_COMMUNICATOR_PARENT_EVENTS = exports.CONFIGURATOR_COMMUNICATOR_EVENTS = void 0;
var CONFIGURATOR_COMMUNICATOR_EVENTS;
(function (CONFIGURATOR_COMMUNICATOR_EVENTS) {
    CONFIGURATOR_COMMUNICATOR_EVENTS["ADD_TO_BASKET"] = "ADD_TO_BASKET";
    CONFIGURATOR_COMMUNICATOR_EVENTS["EXIT"] = "EXIT";
    CONFIGURATOR_COMMUNICATOR_EVENTS["HOME"] = "HOME";
    CONFIGURATOR_COMMUNICATOR_EVENTS["PRODUCT_LIST"] = "PRODUCT_LIST";
    CONFIGURATOR_COMMUNICATOR_EVENTS["GET_PRODUCTS_DATA"] = "GET_PRODUCTS_DATA";
})(CONFIGURATOR_COMMUNICATOR_EVENTS = exports.CONFIGURATOR_COMMUNICATOR_EVENTS || (exports.CONFIGURATOR_COMMUNICATOR_EVENTS = {}));
var CONFIGURATOR_COMMUNICATOR_PARENT_EVENTS;
(function (CONFIGURATOR_COMMUNICATOR_PARENT_EVENTS) {
    CONFIGURATOR_COMMUNICATOR_PARENT_EVENTS["SET_PRODUCTS_DATA"] = "SET_PRODUCTS_DATA";
})(CONFIGURATOR_COMMUNICATOR_PARENT_EVENTS = exports.CONFIGURATOR_COMMUNICATOR_PARENT_EVENTS || (exports.CONFIGURATOR_COMMUNICATOR_PARENT_EVENTS = {}));
